/* RegistrationPage.css */

.registration-container {
  padding: 20px;
  max-width: 500px;
  margin: auto;
  text-align: center;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

p {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.register-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
}

.register-button.google {
  background-color: #db4437; /* Gmail Red */
}

.register-button.facebook {
  background-color: #4267b2; /* Facebook Blue */
}

.register-button.email {
  background-color: #007bff; /* Standard Blue */
}

.register-button:hover {
  opacity: 0.8;
}

.login-prompt {
  margin-top: 20px;
  font-size: 1rem;
}

.login-prompt a {
  color: #007bff;
  text-decoration: none;
}

.login-prompt a:hover {
  text-decoration: underline;
}
