.App {
  text-align: center;
}
ul.navbar-nav .show>.nav-link, ul.navbar-nav .nav-link.active {
  color: #00c2ff;
  font-weight: bold;
}
a.nav-link:hover, a.nav-link:focus {
  color: #0a9cca;
}
.navbar.navbar-scroll:hover {
  background: #287ea1;
}
.todo-app {
  background-color: #efefef;
  padding: 1.2em;
  .new-todo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    input{
      width: 80% !important;
    }
  }
}

/* Basic layout styles */

.row {
  height: 100%;
}

/* Left Side Styles */
.left-side {
  background-color: #f8f9fa; // Light grey background
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.input-group {
  display: flex;
  align-items: center;
}

.form-control {
  margin-right: 0.5rem;
}

.btn-primary {
  margin-right: 0.5rem;
}

.right-side {
  background-color: #ffffff; // White background
  padding: 1rem;
}

//.list-group-item{
//  &.active:hover{
//
//  }
//  &active:hover{
//    background-color: #d3d3d3;
//  }
//}