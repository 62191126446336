.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

.login-container {
  max-width:950px;
  height:auto;
}