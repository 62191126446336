card.hover-shadow-light,
.hover-shadow-light {
    transition: all 0.5s ease-in-out;
}

.card.hover-shadow-light:hover,
.hover-shadow-light:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.5s ease-in-out;
}

.navbar-scroll .navbar-brand {
    color: #fff;
}
.navbar-scrolled {
    background-color: #7d8488;
}
.navbar-scrolled .navbar-brand,
.navbar-scrolled .nav-link,
.navbar-scrolled .fa-bars {
    color: #fff;
}
@media (max-width: 991px) {
    .navbar-scroll {
        background-color: #7d8488;
    }
}

#intro {
    height: 100vh;
}

@media (max-width: 768px) {
    #intro {
        height: 26vh;
    }
    .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        padding-right: var(--bs-gutter-x, 2.25rem) !important;
        padding-left: var(--bs-gutter-x, 0.5rem) !important;
    }
}