/* WelcomePage.css */

//#root {
//  background-image:url('../../public/WelcomeBackground2.png');
//  background-size:100% auto;
//  background-repeat:no-repeat;
//}

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.graphic-container {
  width: 100%;
  height: 50vh; /* Adjust based on your graphic size */
}

.welcome-graphic {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Make sure the image covers the container */
}

.text-container {
  margin: 20px 0;
}

.text-container h1 {
  font-size: 2.5rem;
  margin: 0;
}

.text-container p {
  font-size: 1.25rem;
  color: #666; /* Adjust color as needed */
}

.footer-container {
  width: 100%;
  padding: 20px;
}

.checkbox-label {
  display: block;
  margin-bottom: 20px;
  font-size: 0.9rem;
  color: #333; /* Adjust color as needed */
}

.checkbox-input {
  margin-right: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none; /* Remove underline from links */
  transition: background-color 0.3s ease;
  color: white; /* Text color */
  text-align: center;
  width:210px;
  margin:auto;
}

.button:first-child {
  background-color: #007bff; /* Blue color */
  color: white;
}

.button:first-child:hover {
  background-color: #0056b3;
}

.button:last-child {
  background-color: #28a745; /* Green color */
  color: white;
}

.button:last-child:hover {
  background-color: #218838;
}
